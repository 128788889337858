var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"effect-wrap"},[_c('div',{staticClass:"content-wrap"},[(_vm.list.length > 0)?_vm._l((_vm.list),function(data,idx){return _c('div',{key:idx,staticClass:"vf-sound-effect"},[_c('div',{staticClass:"vf-sound-effect-top"},[_c('div',{staticClass:"top-cover",on:{"click":function($event){return _vm.handlePlayerMusic({
            ...data,
            type: 3,
            labels: data.label
          })}}},[_c('img',{staticClass:"record",attrs:{"src":require('@/assets/image/soundEffect-record.png')}}),_c('div',{staticClass:"player-btn"},[_c('img',{class:['player-control', { 'player-control-pause': !(_vm.isPlay && _vm.objectId == data.object_id) }],attrs:{"src":require(`@/assets/svg/sound-effect-${_vm.isPlay && _vm.objectId == data.object_id ? 'play' : 'pause'}.svg`)}})])]),_c('div',[_c('p',{staticClass:"top-title"},[_c('span',{staticClass:"text-over-e"},[_vm._v(_vm._s(data.name))])]),_c('p',{staticClass:"top-tag text-over-e"},_vm._l((data.label && data.label.length
                ? data.label.slice(0, 2)
                : []),function(item,index){return _c('Tag',{key:index,attrs:{"plain":""}},[_vm._v(_vm._s(item.name))])}),1)])]),_c('div',{staticClass:"vf-sound-effect-bottom"},[_c('p',{staticClass:"icon"},[_c('img',{attrs:{"src":require(`@/assets/svg/operation/${data.is_collect === 0 ? 'collect' : 'collected'}.svg`),"alt":""},on:{"click":function($event){return _vm.handleSoundCollect(data)}}}),_c('img',{attrs:{"src":require('@/assets/svg/operation/share.svg')},on:{"click":function($event){return _vm.handleShare('', data.object_id, 3, data.name)}}})]),_c('p',{staticClass:"btn",on:{"click":function($event){return _vm.handleDownLoadForFree(data)}}},[_vm._v(" 立即下载 ")])])])}):_vm._e()],2),(_vm.list.length === 0)?_c('el-empty',{staticStyle:{"margin":"0 auto"}}):_vm._e(),(JSON.stringify(_vm.pageQuery) !== '{}')?_c('div',{staticClass:"vf-music-pagination"},[(_vm.pageQuery.total > 0)?_c('Pagination',{attrs:{"hiddenPage":true,"position":"center","limit":_vm.pageQuery.page_size,"page":_vm.pageQuery.page,"total":_vm.pageQuery.total},on:{"update:limit":function($event){return _vm.$set(_vm.pageQuery, "page_size", $event)},"update:page":function($event){return _vm.$set(_vm.pageQuery, "page", $event)},"pagination":_vm.pagination}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }