<template>
  <div class="effect-wrap">
    <div class="content-wrap">
      <template v-if="list.length > 0">
        <div class="vf-sound-effect" v-for="(data, idx) in list" :key="idx">
          <div class="vf-sound-effect-top">
            <div class="top-cover" @click="handlePlayerMusic({
              ...data,
              type: 3,
              labels: data.label
            })">
              <img class="record" :src="require('@/assets/image/soundEffect-record.png')" />
              <div class="player-btn">
                <img :class="['player-control', { 'player-control-pause': !(isPlay && objectId == data.object_id) }]"
                  :src="require(`@/assets/svg/sound-effect-${isPlay && objectId == data.object_id ? 'play' : 'pause'}.svg`)" />
              </div>
            </div>

            <div>
              <p class="top-title">
                <span class="text-over-e">{{ data.name }}</span>
                <!-- <img v-if="data.is_show_hot === 1"
                  :src="require(`@/assets/svg/${orderNo == 2 && data.is_show_hot === 1 ? 'hot-badge.svg' : 'new-icon.svg'}`)" /> -->
                <!--          <img v-if="data.is_recommend === 1" :src="require('@/assets/svg/hot-badge.svg')" />-->
              </p>
              <p class="top-tag text-over-e">
                <Tag plain v-for="(item, index) in data.label && data.label.length
                  ? data.label.slice(0, 2)
                  : []" :key="index">{{ item.name }}</Tag>
              </p>
            </div>
          </div>
          <div class="vf-sound-effect-bottom">
            <p class="icon">
              <img :src="require(`@/assets/svg/operation/${data.is_collect === 0 ? 'collect' : 'collected'}.svg`)"
                alt="" @click="handleSoundCollect(data)">
              <img :src="require('@/assets/svg/operation/share.svg')"
                @click="handleShare('', data.object_id, 3, data.name)" />
              <!-- <img :src="require('@/assets/svg/operation/add.svg')" @click="handleAddGoodsToCart({ object_id: data.object_id, type: 3, name: data.name })" /> -->
            </p>
            <p class="btn" @click="handleDownLoadForFree(data)">
              立即下载
            </p>
          </div>
        </div>
      </template>
    </div>

    <el-empty style="margin: 0 auto;" v-if="list.length === 0" />

    <!-- 分页器 -->
    <div class="vf-music-pagination" v-if="JSON.stringify(pageQuery) !== '{}'">
      <Pagination v-if="pageQuery.total > 0" :hiddenPage="true" position="center" :limit.sync="pageQuery.page_size"
        :page.sync="pageQuery.page" @pagination="pagination" :total="pageQuery.total" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Tag from '@/components/Tag'
import Buttons from '@/layout/mixins/Buttons'
import { getToken } from "@/utils/author";

export default {
  components: { Tag, Pagination },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    orderNo: {
      type: Number
    },
    pageQuery: {
      type: Object,
      default: () => { }
    },
  },
  computed: {
    isPlay() {
      return this.$store.state.music.isPlaying;
    },
    objectId() {
      return this.$store.state.music?.tmpMusicInfo?.object_id || null
    },
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
  },
  mixins: [Buttons],
  methods: {
    show(){
      this.$parent.$refs.vipTipDialog.showModal()
    },
    pagination() {
      this.$emit('pagination')
    },
    async handleSoundCollect(item) {
      if (this.musicInfo && this.musicInfo.object_id) {
        if (item.object_id === this.musicInfo.object_id) {
          this.$message.warning('正在播放的音效请在播放器中操作')
          return
        }
      }
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 3, {
        ...item,
        type: 3,
        labels: item.label
      }, false)
    }
  }
}
</script>

<style lang="scss" scoped>
.effect-wrap {
  width: 100%;
}

.content-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px 20px;
}

.vf-sound-effect {
  @include size(100%, 130px);
  background: $base-color;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  box-sizing: border-box;
  cursor: pointer;

  &:nth-child(4n+4) {
    margin-right: 0;
  }

  &-top {
    @include center-middle-flex($justify: flex-start);
    border-bottom: 2px solid #F5F6FA;
    padding-bottom: 8px;

    .top-cover {
      position: relative;
      margin-right: 12px;
      @include square(60px);

      .record {
        @include square(100%);
      }

      .player-control {
        @include square(20px);
        @include center-middle-position;
      }

      .player-control-pause {
        left: 52%;
      }

      .player-btn {
        @include square(32px);
        @include center-middle-position;
        background: #FFFFFF;
        border-radius: 50%;
      }
    }

    .top-title {
      @include font-bold($size: 14px, $color: rgba(0, 0, 0, 0.8));
      @include center-middle-flex($justify: flex-start);
      line-height: 20px;
      margin-bottom: 8px;

      // span {
      //   @include text-overflow(104px);
      // }

      img {
        @include size(28px, 20px);
        margin-top: -12px;
        margin-left: 8px;
      }
    }

    .top-tag {
      .vf-tag-block {
        margin-right: 4px;
      }

      .vf-tag-block:nth-last-of-type(1) {
        margin-right: 0;
      }

      .vf-tag-plain {
        border-radius: 6px;
        border: 1px solid rgba(195, 195, 205, 0.6);
      }
    }
  }

  &-bottom {
    @include center-middle-flex($justify: space-between);
    margin-top: 12px;

    .icon {
      width: 118px;
      margin-left: 8px;

      img {
        @include square(17.2px);
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .btn {
      @include font-normal($size: 12px, $color: #E52A0D);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border-radius: 2px;
      border: 1px solid #E52A0D;
      @include size(72px, 24px);
      line-height: 24px;
      text-align: center;
    }
  }
}

.vf-music-pagination {
  width: 100%;
  margin-top: 50px;
}
</style>
