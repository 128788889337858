<template>
  <div class="limit-exempt-detail-container">
    <!-- banner -->
    <div class="banner">
      <div class="banner-container">
        <!-- <img src="@/assets/image/limitExempt/limit-exempt-bg.jpg" alt=""> -->
      </div>
      <div class="crumb-wapper container_media">
        <vf-breadcrumb :data="breadData" type="banner"></vf-breadcrumb>
      </div>
      <div class="search">
        <div class="centerShow">
          <h3>精选素材</h3>
          <h4>SELECTED MATERIAL</h4>
          <!-- 搜索框 -->
          <Search ref="search" mode="nav" class="search-input" style="margin: 0 auto"
            placeholder="请输入关键词搜索音乐、视频、音效" @search="handleSearch" />
        </div>
      </div>
    </div>
    <div class="detail-container container_media">
      <div class="list-title">
        <div class="tab-con">
          <ul class="tab-model">
            <li :class="activeName === item.value ? 'tab active' : 'tab'" v-for="(item, idx) in tabList" :key="idx"
              @click="handleTabClick(item)">
              <img :src="require(`@/assets/image/limitExempt/tab_${item.value}${activeName === item.value ? '_select' : ''}_icon.svg`)" alt="">
              {{item.label}}
            </li>
          </ul>
        </div>
        <div class="order_cont">
          <!-- <label for="">排序：</label> -->
          <el-select v-model="sortType" placeholder="请选择" :popper-append-to-body="false" @change="changeSortType">
            <el-option v-for="item in orderList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="list-content" v-loading="fetchLoading">
        <com-music-list v-show="activeName === 'music'" :list="musicList"
        :pageQuery="musicListQuery" @pagination="getMusicList"></com-music-list>
        <com-video-list v-show="activeName === 'video'" :list="videoList"
          :pageQuery="videoListQuery" @pagination="getVideoList"></com-video-list>
        <com-sound-list v-show="activeName === 'sound'" :list="soundList"
          :pageQuery="soundListQuery" @pagination="getSoundList"></com-sound-list>
        </div>
    </div>
    <VipTipDialog ref="vipTipDialog"></VipTipDialog>
  </div>
</template>

<script>
import Search from '@/components/Search'
import Buttons from '@/layout/mixins/Buttons'
// import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'
import ComMusicList from './components/MusicList.vue'
import ComVideoList from './components/VideoList.vue';
import ComSoundList from './components/SoundList.vue'
import { orderList } from '@/config'
import { fetchMusicList } from "@/api/music";
import { fetchVideo } from "@/api/video";
import { fetchSoundEffect } from "@/api/soundEffect.js";
import VipTipDialog from '../components/VipTipDialog.vue'
import EventBus from '@/utils/bus.js'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'LimitExemptDetail',
  components: { Search, ComMusicList, ComVideoList, ComSoundList, VipTipDialog, VfBreadcrumb},
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '限免专区', path: '/limitExempt'},
        {breadcrumbName: '精选音乐'}
      ],
      orderList,
      sortType: '',
      activeName: 'music',
      keyword_name: '',
      tabList: [{
        label: '精选音乐',
        value: 'music'
      }, {
        label: '精选视频',
        value: 'video'
      }, {
        label: '精选音效',
        value: 'sound'
      }],
      musicList: [],
      videoList: [],
      soundList: [],
      musicListQuery: {
        total: 0,
        page_size: 10,
        page: 1
      },
      videoListQuery: {
        total: 0,
        page_size: 16,
        page: 1
      },
      soundListQuery: {
        total: 0,
        page_size: 16,
        page: 1
      },
      fetchLoading: false,
      sort: '',
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  watch: {
    '$store.state.music.tmpMusicInfo'(val) {
      const { is_collected, object_id, type } = val || {}
      if (val) {
        const list = type == 2 ? this.musicList : type == 3 ? this.soundList : []
        if (object_id) {
          const index = list.findIndex((i) => i.object_id === object_id)
          if (index > -1) {
            list[index].is_collect = is_collected
            this.$emit('update:data', list)
          }
        }
      }
    },
  },
  async created() {
    this.activeName = this.$route.query.type || 'music'
    this.breadData[2].breadcrumbName = this.activeName == 'video' ? '精选视频' : this.activeName == 'sound' ? '精选音效' : '精选音乐'
    this.keyword_name = this.$route.query.keyword_name || ''
    this.getList()
  },
  mounted(){
    EventBus.$on("collect", (data) => {
      const index = this.videoList.findIndex((i) => i.object_id === data.object_id)
      if(index > -1) this.videoList[index].is_collect = data.is_collect
    });
  },
  mixins: [Buttons],
  methods: {
    handleTabClick(item) {
      this.activeName = item ? item.value : 'music'
      this.breadData[2].breadcrumbName = this.activeName == 'video' ? '精选视频' : this.activeName == 'sound' ? '精选音效' : '精选音乐'
      if(this.keyword_name){
        this.$refs.search.keyword_name = ''
        this.keyword_name = ''
      }
      this.getList()
    },
    handleSearch(item){
      this.keyword_name = item.keyword_name
      this.fetchLoading = true
      switch (this.activeName) {
        case 'music':
          this.musicListQuery.page = 1
          this.getMusicList();
          break;
        case 'video':
          this.videoListQuery.page = 1
          this.getVideoList();
          break;
        case 'sound':
          this.soundListQuery.page = 1
          this.getSoundList()
          break;
      }
    },
    // 按最新｜最热排序
    changeSortType(v) {
      this.sortType = v
      this.getList()
    },
    getList(){
      this.fetchLoading = true
      switch (this.activeName) {
        case 'music':
          this.getMusicList();
          break;
        case 'video':
          this.getVideoList();
          break;
        case 'sound':
          this.getSoundList()
          break;
      }
    },
     /**
     * 获取最新音乐
     */
    async getMusicList() {
      try {
        const { data } = await fetchMusicList({
          page: this.musicListQuery.page,
          limit: this.musicListQuery.page_size,
          keyword_name: this.keyword_name,
          order: this.sortType,
          is_free: 1
        });
        this.fetchLoading = false
        this.musicList = data.list;
        this.musicListQuery.total = data.total
      } catch (e) {
        this.fetchLoading = false
        console.log(e);
      }
    },
    /**
     * 获取推荐视频
     */
    async getVideoList() {
      try {
        const { data } = await fetchVideo({
          page: this.videoListQuery.page,
          limit: this.videoListQuery.page_size,
          order: this.sortType,
          keyword_name: this.keyword_name,
          is_free: 1
        });
        this.fetchLoading = false
        this.videoList = data.list
        this.videoListQuery.total = data.total
        // console.log(this.video.data, '推荐视频')
      } catch (e) {
        this.fetchLoading = false
        console.log(e);
      }
    },
    async getSoundList() {
      try {
        const { data } = await fetchSoundEffect({
          page: this.soundListQuery.page,
          limit: this.soundListQuery.page_size,
          order: this.sortType,
          keyword_name: this.keyword_name,
          is_free: 1
        });
        this.fetchLoading = false
        this.soundList = data.list
        this.soundListQuery.total = data.total
      } catch (e) {
        this.fetchLoading = false
        console.log(e);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      span {
        padding-left: 15px !important;
      }
    }
  }
}

.limit-exempt-detail-container {
  min-height: 100vh;
  min-width: 1000px;
  padding-bottom: 60px;

  .banner {
    width: 100%;
    position: relative;
    .crumb-wapper {
      width: 100%;
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }
    .banner-container {
      width: 100%;
      height: 260px;
      box-sizing: border-box;
      opacity: .7;
      background: url('../../../assets/image/limitExempt/limit-exempt-bg_0901.jpg') no-repeat;
      background-size: 100% 100%;
      background-position: center;
      box-shadow: 0 -10px 10px -2px #fff inset;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .search {
      position: absolute;
      @include size(100%, auto);
      left: 0;
      top: 60px;

      h3, h4 {
        text-align: center;
      }
      h3 {
        color: #fff;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: 4px;
        line-height: 50px;
        margin-bottom: 4px;
      }
      h4 {
        color: #F5F6FA;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 2px;
        margin: 0 0 40px 0;
      }

      /deep/.search-container {
        @include size(786px, 56px);
        border-radius: 0px!important;
        .input {
          border: none;
          background: rgba(255, 255, 255, 0.8);
          opacity: .8;
          border-radius: 0px!important;
          box-shadow: 0px 2px 12px 0px rgba(0,0,0,.2);

          input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-moz-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .btn {
          font-size: 18px;
          border-radius: 0px!important;
        }
      }
    }
  }

  /deep/ {
    .search-container {
      .input-text {
        color: #ffffff;

        .el-input__inner {
          color: #777;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding-left: 24px;
        }

        .el-input__inner::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  .detail-container {
    margin-top: 62px;
    .list-title {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      position: relative;
      margin-bottom: 36px;
      .tab-con {
        margin: 0 auto;
        width: 428px;
      }
      .tab-model {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px 0;
        border: 1px solid #EDEDED;
        border-radius: 4px;
        .tab {
          @include center-middle-flex($justify: center);
          width: 142px;
          height: 22px;
          line-height: 22px;
          font-size: 16px;
          color: #666666;
          img{
            margin-right: 8px;
          }
        }
        .tab:nth-child(2){
          border-right: 1px solid #EDEDED;
          border-left: 1px solid #EDEDED;
        }

        .active {
          font-weight: 600;
          color: #E52A0D;
          position: relative;
          &:after {
            content: '';
            width: 90px;
            height: 4px;
            background-color: rgba(229, 42, 13, .2);
            position: absolute;
            bottom: 1px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .order_cont {
        position: absolute;
        top: 20px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }

        /deep/.el-select {
          width: 100px !important;
          height: 32px !important;
          border: 1px solid #EDEDED;
          // border-radius: 5px;

          .el-input--suffix .el-input__inner {
            width: 108px !important;
            height: 32px !important;
            border: none;
            background: none;
          }

          .el-input__icon {
            line-height: 32px !important;
          }
        }
      }
    }

  }
}
</style>
